import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronUp, faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { NavLink } from "react-router-dom";

const MoveSection = ({
  moduleNo,
  lessonNo,
  title,
  keys,
  arrow,
  overviewPage,
  setOverviewPage,
  id,
}) => {
  // const[arrow, setArrow] = useState(arrow)
  const content =
    title === "Home" || title === "Completed"
      ? `${title}`
      : `Lesson ${moduleNo + 1}.${lessonNo + 1}-${title}`;
  const origin = arrow ? "prev" : "next";
  return title !== "Completed" ? (
    <div className="text-center px-0 py-0"  style={{backgroundColor: '#f0f0f0', height: '61px'}}>
      <NavLink
        className="text-decoration-none text-dark"
        to={`/course/${id}`}
        replace={true}
        state={{
          module_no: moduleNo,
          currentLessonNumber: lessonNo,
          origin: `${origin}`,
        }}
      >
        <div
          role="button"
          onClick={() => {
            if (title === "Home") setOverviewPage(!overviewPage);
          }}
        >
          {arrow && <FontAwesomeIcon icon={faChevronUp} size="md" style={{color: '#000'}} />}
          <p className={arrow? 'm-0 pb-3 move-section' : 'm-0 pt-3 move-section'} style={{color: "#000", fontFamily: 'lato', fontWeight: '700'}}>
            {content}
          </p>
          {!arrow && title !== "Completed" && (
            <FontAwesomeIcon icon={faChevronDown} size="md"style={{color: '#000'}}  />
          )}
        </div>
      </NavLink>
    </div>
  ) : (
    <div></div>
  );
};

export default MoveSection;
