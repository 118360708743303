import React from "react";

const Line = () => {
    return (
        <div
            className="progress mt-5 w-100  sticky-top"
            style={{ height: "2px" }}
        >
            <div
                className="progress-bar bg-success w-100"
                role="progressbar"
                aria-valuenow="0"
                aria-valuemin="0"
                aria-valuemax="50"
            ></div>
        </div>
    );
};

export default Line;
