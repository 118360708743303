import React from "react";

const CourseContent = ({
    lessonTitle,
    description,
    keys,
    detailDescription,
    contentData,
}) => {
    console.log(contentData);

    return (
        <div className={`col px-5 my-3 w-100 d-flex flex-column justify-content-center`} key={keys}>
            <div>
                <h2 className="mb-2 topic-title" >{lessonTitle}</h2>
                <p className="detail-description">{description}</p>
            </div>
            <div
                style={{
                    wordBreak: "break-word",                        // Breaks long words if necessary
                }}
                className="detail-description"
                dangerouslySetInnerHTML={{
                    __html: detailDescription,
                }}

            // onMouseOver={(event) => {
            //   //   if (event.target.tagName === "SPAN") {
            //   setHoveredElement(event.target);
            //   //   }
            // }}
            />
            {/* <div className="embed-responsive embed-responsive-4by3 pl-4 p-5 mb-5 col-8 d-flex justify-content-center">
        <iframe
          className="embed-responsive-item w-100"
          style={{ height: "300px" }}
          src={"https://www.youtube.com/embed/NZKUirTtxcg?si=ewGtR_8NWi3x08iT"}
          title={"YouTube video player"}
          frameborder={"0"}
          allow={
            "accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          }
          referrerpolicy={"strict-origin-when-cross-origin"}
          allowfullscreen
        ></iframe>
      </div> */}
            {contentData.map((contentItem, contentIndex) => (
                <div key={contentIndex} style={{ position: "relative", width: '100%' }} className="my-3">
                    <em className="youtube-title">
                        {contentItem.titleDescription}
                    </em>

                    {contentItem?.YoutubeLinks &&
                        contentItem?.YoutubeLinks?.length > 0 &&
                        ((contentItem?.YoutubeLinks[0]?.URL &&
                            contentItem?.YoutubeLinks[0]?.title) ||
                            (contentItem?.YoutubeLinks[0][0]?.URL &&
                                contentItem?.YoutubeLinks[0][0]?.title)) && (
                            // <div
                            //   style={{
                            //     display: "grid",
                            //     gridTemplateColumns: "repeat(auto-fit, minmax(200px, 1fr))",
                            //     gap: "10px",
                            //   }}
                            //   className="m-2"
                            // >
                            //   <div className="video-container">
                            //     <iframe
                            //       src={
                            //         contentItem?.YoutubeLinks &&
                            //         contentItem?.YoutubeLinks[0]?.URL
                            //           ? contentItem?.YoutubeLinks[0].URL
                            //           : contentItem?.YoutubeLinks?.[0][0]?.URL
                            //       }
                            //       title={
                            //         contentItem?.YoutubeLinks &&
                            //         contentItem?.YoutubeLinks[0]?.title
                            //           ? contentItem?.YoutubeLinks[0].title
                            //           : contentItem?.YoutubeLinks?.[0][0]?.title
                            //       }
                            //       className="rounded shadow-sm"
                            //       style={{ border: "none" }}
                            //       allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            //     ></iframe>
                            //   </div>

                            //   {/* {contentItem.YoutubeLinks.map((linkGroup, i) => (
                            //   <div key={i} className="video-container">
                            //     <iframe
                            //       src={
                            //         linkGroup && linkGroup[0]?.URL
                            //           ? linkGroup[0].URL
                            //           : linkGroup?.URL
                            //       }
                            //       title={
                            //         linkGroup && linkGroup[0]?.title
                            //           ? linkGroup[0].title
                            //           : linkGroup?.title
                            //       }
                            //       className="rounded shadow-sm"
                            //       style={{ border: "none" }}
                            //       allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            //     ></iframe>
                            //   </div>
                            // ))} */}
                            // </div>
                        <div className="d-flex flex-column justify-content-center align-items-center my-2">
                            <div
                                className="embed-responsive embed-responsive-16by9"
                            >
                                <iframe
                                    className="embed-responsive-item rounded border-0 shadow-none"
                                    src={
                                        contentItem?.YoutubeLinks &&
                                            contentItem?.YoutubeLinks[0]?.URL
                                            ? contentItem?.YoutubeLinks[0].URL
                                            : contentItem?.YoutubeLinks?.[0][0]?.URL
                                    }
                                    title={
                                        contentItem?.YoutubeLinks &&
                                            contentItem?.YoutubeLinks[0]?.title
                                            ? contentItem?.YoutubeLinks[0].title
                                            : contentItem?.YoutubeLinks?.[0][0]?.title
                                    }
                                    allow={
                                        "accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                    }
                                    referrerPolicy={"strict-origin-when-cross-origin"}
                                    allowFullScreen
                                ></iframe>
                            </div>
                        </div>
                        )}
                </div>
            ))}
        </div>
    );
};

export default CourseContent;
