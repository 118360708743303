import React from "react";

const LoginExpired = () => {
  const containerStyle = {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
    height: "100vh",
    background: "#3a3a8f",
    color: "#fff",
    fontFamily: "Arial, sans-serif",
  };

  const boxStyle = {
    padding: "20px",
    background: "rgba(0, 0, 0, 0.5)",
    borderRadius: "10px",
    boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
    maxWidth: "600px",
    width: "90%",
  };

  const errorCodeStyle = {
    fontSize: "6rem",
    fontWeight: "bold",
    marginBottom: "10px",
  };

  const headingStyle = {
    fontSize: "2.5rem",
    marginBottom: "10px",
  };

  const paragraphStyle = {
    fontSize: "1.2rem",
    marginBottom: "20px",
  };

  const buttonStyle = {
    textDecoration: "none",
    background: "#fff",
    color: "#ff7e5f",
    padding: "10px 20px",
    borderRadius: "5px",
    fontSize: "1rem",
    fontWeight: "bold",
    transition: "background 0.3s, color 0.3s",
  };

  const buttonHoverStyle = {
    background: "#ff7e5f",
    color: "#fff",
  };

  const handleMouseEnter = (e) => {
    Object.assign(e.target.style, buttonHoverStyle);
  };

  const handleMouseLeave = (e) => {
    Object.assign(e.target.style, buttonStyle);
  };

  return (
    <div style={containerStyle}>
      <div style={boxStyle}>
        <div style={errorCodeStyle}>404</div>
        <h1 style={headingStyle}>Login Expired</h1>
        <p style={paragraphStyle}>
          Please return to the homepage to log in again.
        </p>
        <a
          href="/"
          style={buttonStyle}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          Return to Home
        </a>
      </div>
    </div>
  );
};

export default LoginExpired;
