import React from "react";
import { useNavigate } from "react-router-dom";

const CourseList = ({ results }) => {
  const navigate = useNavigate();
  const handleClick = (id) => {
    navigate(`/coursedata/internal/${id}`);
  };
  const handlePreviewClick = (id) => {
    window.open(`/course/${id}`, "_blank");
  };
  return (
    <div id="image_grid" className="container-fluid m-3 mt-5">
      {results.length ? (
        results?.map((article, i) => (
          <>
            {/* <div id="article_div" className="shadow bg-white rounded" key={i}>
              <div onClick={() => handleClick(article.uuid)}>
                <div className="p-5" title={article.courseTitle}>
                  <div>
                    <div id="article_headline">
                      <h5>
                        {article.courseTitle
                          ? article.courseTitle.charAt(0).toUpperCase() +
                            article.courseTitle.slice(1, 50) +
                            (article.courseTitle.length > 50 ? "..." : "")
                          : ""}
                      </h5>
                    </div>
                  </div>
                </div>
              </div>
            </div> */}
            <div
              id="article_div"
              className="shadow bg-white rounded"
              key={i}
              style={{ position: "relative" }}
            >
              <div onClick={() => handleClick(article.uuid)}>
                <div className="p-5" title={article.courseTitle}>
                  <h5 style={{ marginBottom: "40px" }}>
                    {article.courseTitle
                      ? article.courseTitle.charAt(0).toUpperCase() +
                        article.courseTitle.slice(1, 50) +
                        (article.courseTitle.length > 50 ? "..." : "")
                      : ""}
                  </h5>
                </div>
              </div>
              <button
                className="preview-button mr-4 pb-2"
                title="Preview"
                onClick={(e) => {
                  e.stopPropagation(); // Prevent triggering the parent div's `onClick`
                  handlePreviewClick(article.uuid);
                }}
                style={{
                  position: "absolute",
                  bottom: "10px",
                  right: "10px",
                  background: "none",
                  border: "none",
                  cursor: "pointer",
                  color: " #3a3a8f",
                  padding: "0",
                  outline: "none",
                }}
              >
                <i className="fas fa-eye"></i>
              </button>
            </div>
          </>
        ))
      ) : (
        <p className="m-5 text-center">No Courses Found</p>
      )}
    </div>
  );
};

export default CourseList;
