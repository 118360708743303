import React, { useEffect, useRef, useState } from "react";
import Overview from "./Overview";
import CourseContent from "./CourseContent";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import Title from "./Title";
import Line from "./Line";
import MoveSection from "./MoveSection";
import Counts from "../data/Counts.json";
import { useLocation, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import axios from "axios";
import {Helmet} from "react-helmet"
import "./course.css"

const Course = () => {
    const { id } = useParams();
    const cid = useRef(id);
    console.log(id, "----");
    const [topics, setTopics] = useState([]);
    const [overviewPage, setOverviewPage] = useState(true);
    const [hideOverview, setHideOverview] = useState(false);
    const [courseDatas, setCourseDatas] = useState([]);
    const [modulesCompleted, setModulesCompleted] = useState({});
    const [loading, setLoading] = useState(false);
    const [courseTitle, setCourseTitle] = useState("");
    const courseContentRef = useRef(null);
    const BASE_URL = process.env.REACT_APP_API_KEY;

    const scroll = {
        overflow: "scroll",
        maxHeight: "100vh",
        overflowX: "hidden",
        overflowY: "auto",
        // height: "100vh",
    };

    const location = useLocation();
    const { module_no, currentLessonNumber, origin } = location.state || {};
    const moduleNo = module_no;
    const currentLessonNo = currentLessonNumber;

    const isSmallScreen = window.innerWidth <= 375;

    useEffect(() => {
        getCourseData(id).then((res) => {
            if (res) {
                console.log(res, "----rwsp");
                setCourseDatas(res?.modules);
                setCourseTitle(res?.title);
                const modules = res?.modules || [];
                // console.log(modules)
                const titles = modules.map((module) => module.moduleTitle);
                const completedStatus = Object.fromEntries(
                    modules.map((module) => [module.moduleTitle, modules.completed])
                );
                setModulesCompleted(completedStatus);
                setTopics(titles);
                courseContentRef.current?.scrollIntoView({ behavior: "smooth" });
            }
        });
    }, []);

    useEffect(() => {
        if (
            moduleNo - 1 >= 0 &&
            moduleNo - 1 < topics.length &&
            origin !== "sidebar" &&
            currentLessonNumber === 0
        ) {
            setCourseDatas((prev) => {
                const updatedCourseDatas = [...prev];

                if (updatedCourseDatas[moduleNo - 1]) {
                    console.log(updatedCourseDatas[moduleNo - 1]);
                    console.log(updatedCourseDatas[moduleNo]);
                    updatedCourseDatas[moduleNo - 1] = {
                        ...updatedCourseDatas[moduleNo - 1],
                        completed: true,
                        currentLessonNo: 0,
                    };
                }

                return updatedCourseDatas;
            });

            setModulesCompleted((prev) => ({
                ...prev,
                [topics[moduleNo - 1]]: true,
            }));
        }

        courseContentRef.current?.scrollIntoView({ behavior: "smooth" });
        console.log(courseContentRef.current);
    }, [moduleNo, topics, topics.length, currentLessonNumber, origin]);

    useEffect(() => {
        if(isSmallScreen){
            setHideOverview(false)
        }

    }, [isSmallScreen])

    let prevNo = currentLessonNo - 1 < 0 ? -1 : currentLessonNo - 1;
    let nextNo =
        currentLessonNo + 1 >= courseDatas[moduleNo]?.lessons.length
            ? courseDatas[moduleNo]?.lessons.length
            : currentLessonNo + 1;
    const prevModuleNo =
        prevNo === -1 ? (moduleNo - 1 < 0 ? -1 : moduleNo - 1) : moduleNo;
    const nextModuleNo =
        nextNo === courseDatas[moduleNo]?.lessons.length
            ? moduleNo + 1 >= topics.length
                ? topics.length
                : moduleNo + 1
            : moduleNo;
    prevNo =
        prevModuleNo !== moduleNo && prevModuleNo !== -1
            ? courseDatas[prevModuleNo]?.lessons.length - 1
            : prevNo;
    nextNo =
        nextModuleNo !== moduleNo &&
            nextNo === courseDatas[prevModuleNo]?.lessons.length
            ? 0
            : nextNo;

    const getCourseData = async (id) => {
        try {
            setLoading(true);
            const response = await axios.get(`${BASE_URL}/getlist/${id}`);
            // setLoading(false);

            if (response?.data?.data?.courseOutline?.modules) {
                let modules = response.data.data.courseOutline.modules;
                response.data.data["completed"] = false;
                response.data.data.courseOutline["currentModule"] = 0;
                for (let module of modules) {
                    module["completed"] = false;
                    module["currentLessonNo"] = 0;
                }
                setLoading(false);

                return response.data.data.courseOutline;
            } else {
                setLoading(false);

                return false;
            }
            setLoading(false);
        } catch (error) {
            setLoading(false);

            console.error(error);
            toast.error("Error fetching course data");
            return false;
        }
    };
    return (
        <>

            <Helmet>
                <title>{topics[moduleNo]}</title>
            </Helmet>
            {courseDatas && (
                <div>
                    {overviewPage && courseDatas ? (
                        <Overview
                            key={0}
                            overviewPage={overviewPage}
                            setOverviewPage={setOverviewPage}
                            id={cid.current}
                            courseData={courseDatas}
                            courseTitle={courseTitle}
                        />
                    ) : (
                        <div className="container-fluid row d-flex flex-direction-row p-0 m-0 bg-white">
                            {!hideOverview && courseDatas && (
                                <div className="p-0 overview">
                                    <Overview
                                        isHomePage={false}
                                        activeCol={moduleNo}
                                        key={0}
                                        completedStatus={modulesCompleted}
                                        overviewPage={overviewPage}
                                        setOverviewPage={setOverviewPage}
                                        id={cid.current}
                                        courseData={courseDatas}
                                        courseTitle={courseTitle}
                                        hideOverview={hideOverview}
                                        setHideOverview={setHideOverview}
                                    />
                                </div>
                            )}

                            <div
                                className={hideOverview ? "container-fluid bg-white compressed p-0" : "container-fluid col p-0 m-0 bg-white"}
                                style={scroll}
                            >
                                <MoveSection
                                    arrow={true}
                                    title={
                                        origin === "home" || prevModuleNo === -1
                                            ? "Home"
                                            : `${courseDatas[prevModuleNo].lessons[prevNo].lessonTitle}`
                                    }
                                    key={`move-module-${prevModuleNo}-${prevNo}`}
                                    moduleNo={prevModuleNo}
                                    lessonNo={prevNo}
                                    overviewPage={overviewPage}
                                    setOverviewPage={setOverviewPage}
                                    id={id}
                                />

                                <div
                                    className="px-2 py-4 sticky-top "
                                    style={{ cursor: "pointer" }}
                                >
                                    <FontAwesomeIcon
                                        icon={faBars}
                                        size="lg"
                                        onClick={() => setHideOverview(!hideOverview)}
                                    />
                                </div>

                                <div
                                    ref={courseContentRef}
                                    style={{
                                        scrollSnapType: "y mandatory",
                                        scrollMarginTop: "60px",
                                    }}
                                >
                                    {topics[moduleNo] && (
                                        <div className={"courseWidth"}>
                                        <Title name={topics[moduleNo]} key={`title-${moduleNo}`} />
                                        </div>

                                    )}
                                    <Line />
                                    <div className={"courseWidth"}>
                                    {courseDatas[moduleNo] && (
                                        
                                        <CourseContent
                                            lessonTitle={
                                                courseDatas[moduleNo].lessons[currentLessonNo]
                                                    .lessonTitle
                                            }
                                            description={
                                                courseDatas[moduleNo].lessons[currentLessonNo]
                                                    .description
                                            }
                                            detailDescription={
                                                courseDatas[moduleNo].lessons[currentLessonNo]
                                                    .detailcontent
                                            }
                                            contentData={
                                                courseDatas[moduleNo].lessons[currentLessonNo].content
                                            }
                                            keys={`course-content-${moduleNo}`}
                                        />
                                    )}
                                    </div>
                                </div>
                                {nextModuleNo < topics.length && (
                                        <MoveSection
                                            arrow={false}
                                            title={
                                                nextModuleNo < topics.length
                                                    ? `${courseDatas[nextModuleNo].lessons[nextNo].lessonTitle}`
                                                    : "Completed"
                                            }
                                            key={`move-module-${nextModuleNo}-${nextNo}`}
                                            moduleNo={nextModuleNo}
                                            lessonNo={nextNo}
                                            overviewPage={overviewPage}
                                            setOverviewPage={setOverviewPage}
                                            id={cid.current}
                                        />
                                    )}
                            </div>
                        </div>
                    )}
                </div>
            )}

            {/* {loading && (
        <Spinner animation="border" role="status" variant="primary">
          
        </Spinner>
      )} */}
        </>
    );
};

export default Course;
