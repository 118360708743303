import React from "react";

const Title = ({name}) => {
    return (
        <div className="row px-5 m-0 d-flex flex-column" >
            <div>
                <h1 className="p-0 course-title">{name}</h1>
            </div>
            <div className="progress mt-5 p-0 title-after" style={{ height: "5px" }}>
                <div
                    className="progress-bar bg-success w-100"
                    role="progressbar"
                    aria-valuenow="0"
                    aria-valuemin="0"
                    aria-valuemax="50"
                ></div>
            </div>
        </div>
    );
};

export default Title;
