import logo from "./logo.svg";
import "./App.css";
import CourseOutline from "./components/CourseOutline ";
import SearchBarComponent from "./components/SearchComponent";
import { useEffect, useRef, useState } from "react";
import { faL } from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import { Routes, Route, useNavigate } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import CourseData from "./components/CourseData";
import Course from "./components/course/Course";
import LoginExpired from "./components/LoginExpired";

const { v4: uuidv4 } = require('uuid');
function App() {
    const navigate = useNavigate();
    const [searchValue, setSearchValue] = useState(true);
    const [courseStatus, setCourseStatus] = useState(false);
    const [query, setQuery] = useState("");
    const [results, setResults] = useState([]);
    const [loading, setLoading] = useState(false);
    const [courseData, setCourseData] = useState({});
    const [intervalId, setIntervalId] = useState(null);
    const [stageStatus, setStageStatus] = useState(0);
    const [courseList, setCourseList] = useState([]);
    const [courseStage, setCourStage] = useState(null);
    const [isValid, setIsValid] = useState(false);
    const countRef = useRef(stageStatus);
    const [isGenerating, setIsGenerating] = useState(false)
    const [password, setPassword] = useState('');
    const uidRef = useRef(null);
    const BASE_URL = process.env.REACT_APP_API_KEY;
    console.log(BASE_URL, "-------")


    useEffect(() => {
        userSession()
    }, [])
    const handleSearchChange = (e) => {
        setQuery(e.target.value);
    };
    const intervalClear = () => {
        const interval_id = window.setInterval(function () { },
            Number.MAX_SAFE_INTEGER);
        for (let i = 1; i < interval_id; i++) {
            window.clearInterval(i);
        }
    };
    const handleSearchSubmit = async () => {
        if (!query.trim()) return;
        setIsGenerating(true);
        setLoading(true);
        setSearchValue(false);
        // setTimeout(courseList, 200);
        const uid = uuidv4();
        uidRef.current = uid;
        const id = setInterval(getCourseData, 4000);
        console.log(id);
        setIntervalId(id);
        try {
            const response = await axios.post(`${BASE_URL}/generate_outline`, {
                courseTitle: query,
                uuid: uid
            });
            if (response.data) {
                toast.success(`Course generation completed`, {
                    position: "top-right",
                    autoClose: false,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });

                setCourseData(response.data.data.courseOutline)
                setCourStage(null);
                console.log("checking");
                setCourseStatus(false);
                setSearchValue(true);

                userSession();
                navigate(`/courseData/${uid}`)
                setLoading(false);
                intervalClear();
                // if (intervalId) {
                //   clearInterval(intervalId);
                //   setIntervalId(null);
                // }
            }
            setIsGenerating(false)
            setResults(response.data);
        } catch (error) {
            setIsGenerating(false);
            intervalClear()
            const erroData = error?.response?.data?.code;
            userSession();
            setSearchValue(true);
            setCourseStatus(false);
            // if(erroData==403){
            //     navigate(`/courseData/${uidRef.current}`);
            // }
            setLoading(false);
            console.error("Error fetching search results:", error);
            toast.error(error?.response?.data?.error || `Please try again after sometimes`, {
                position: "top-right",
                autoClose: false,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            // setSearchValue(true);
            // setCourseStatus(false);
        }
    };

    const getCourseData = async () => {
        try {
            const response = await axios.get(`${BASE_URL}/getlist/${uidRef.current}`);
            if (response.status == 403) {
                clearInterval(intervalId);
                setIntervalId(null);
                setLoading(false);
                toast.error(`All Youtube API keys exhausted. Try again after a day...!`, {
                    position: "top-right",
                    autoClose: false,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }

            const status_stage = response.data?.data?.status;
            console.log(status_stage, "---stages stage")
            console.log("temp", countRef.current)
            if (response.data?.data) {
                console.log(response.data?.data)
                console.log("Status", status_stage == 1, typeof status_stage, countRef.current == 0, typeof countRef.current)
                if (status_stage == 1 && countRef.current == 0) {
                    setCourseStatus(true);
                    setCourseData(response.data.data.courseOutline);
                    setLoading(false);
                    setCourStage("Generating Descriptive Content");
                    console.log("Stage One - Course outline generated", response.data);

                    toast.success("Stage One - Course outline generated", {
                        position: "top-right",
                        autoClose: false,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                    });

                    setStageStatus((prev) => prev + 1);
                    countRef.current += 1;
                }

                if (status_stage == 2) {
                    setCourseStatus(true);
                    setCourseData(response.data.data.courseOutline);
                    console.log("Stage Two - Large content generated");
                    // if (countRef.current == 1) {
                    //     toast.success("Stage Two - Large content generated", {
                    //         position: "top-right",
                    //         autoClose: false,
                    //         hideProgressBar: true,
                    //         closeOnClick: true,
                    //         pauseOnHover: true,
                    //         draggable: true,
                    //     });
                    // }
                    if (countRef.current == 1) {
                        setStageStatus((prev) => prev + 1);
                        countRef.current += 1;
                    }
                }

                if (status_stage == 3 && countRef.current == 2) {

                    setCourseData(response.data.data.courseOutline);
                    console.log("Stage Three - Final Stage");
                    setCourStage(null);  // Clear the course stage message

                }
            }


            // if (jsonContent) {
            //     setLoading(false);

            //     setSearchValue(false);
            //     if (countRef.current == 0) {
            //         console.log(response.data, "----response")
            //         setCourseData(response.data);
            //         setCourseStatus(true);
            //         console.log("stageone")
            //         toast.success(`Stage one- Course outline generated`, {
            //             position: "top-right",
            //             autoClose: false,
            //             hideProgressBar: true,
            //             closeOnClick: true,
            //             pauseOnHover: true,
            //             draggable: true,
            //             progress: undefined,
            //         });

            //         setStageStatus((prev) => {
            //             const updatedCount = prev + 1;
            //             countRef.current = updatedCount;
            //             return updatedCount;
            //         });
            //     }
            //     if (youtubeSearchTerms) {
            //         if (countRef.current == 1) {
            //             console.log("stageTwo")
            //             toast.success(`Stage Two- Youtube search terms generated`, {
            //                 position: "top-right",
            //                 autoClose: false,
            //                 hideProgressBar: true,
            //                 closeOnClick: true,
            //                 pauseOnHover: true,
            //                 draggable: true,
            //                 progress: undefined,
            //             });
            //             setStageStatus((prev) => {
            //                 const updatedCount = prev + 1;
            //                 countRef.current = updatedCount;
            //                 return updatedCount;
            //             });
            //             setCourseData(response.data);
            //         }

            //         if (youtubeVideoUrl) {
            //             console.log("outside")

            //             if (countRef.current == 2) {
            //                 console.log("stageThree")
            //                 toast.success(`Course generation completed `, {
            //                     position: "top-right",
            //                     // autoClose: 5000,
            //                     hideProgressBar: true,
            //                     closeOnClick: true,
            //                     pauseOnHover: true,
            //                     draggable: true,
            //                     progress: undefined,
            //                 });
            //                 // setStageStatus(3);
            //                 setCourseData(response.data);
            //             }
            //             intervalClear();
            //         }
            //     }
            // }
        } catch (error) {
            setSearchValue(true);
            setCourseStatus(false);
            console.log(intervalId);
            clearInterval(intervalId);
            intervalClear();
            setIntervalId(null);
            console.log(error);
            setLoading(false);
            toast.error(error?.response?.data?.message || `All Youtube API keys exhausted. Try again after a day...!`, {
                position: "top-right",
                // autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            // const jsonContent =
            //     courseData?.courseOutline?.modules?.[0]?.lessons?.[0]?.content?.[0];
            //     console.log(jsonContent)
            //     if(!jsonContent){
            // setSearchValue(true);
            // setCourseStatus(false);
            //     }

        }
    };

    const getCourseList = async (password) => {
        try {
            setLoading(true);
            const response = await axios.post(`${BASE_URL}/getlist`, { password: password, email: "coesify@sifycorp.com" });
            if (response.status == 403) {
                setLoading(false);
                toast.error(`Try again after a day...!`, {
                    position: "top-right",
                    autoClose: false,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }

            if (response.data) {
                setLoading(false);
                setCourseList(response.data.data);
            }
            setIsValid(true);
            localStorage.setItem('userData', JSON.stringify({ user: 'Admin123@', Time: Date.now() }));

        } catch (error) {
            console.log(error);
            setLoading(false);
            toast.error(error?.response?.data?.error || `Error fetching data...!`, {
                position: "top-right",
                // autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });

            {
                isGenerating &&
                setTimeout(() => { navigate('/login-expired') }, 2000)
            }
        }
    };

    const userSession = async () => {
        const userData = localStorage.getItem('userData');
        if (userData) {
            const parsedData = JSON.parse(userData);
            console.log(parsedData);
            if (parsedData?.Time) {
                const currentTime = Date.now();
                const differenceInMillis = currentTime - parsedData?.Time;

                const differenceInMinutes = differenceInMillis / (1000 * 60);


                if (differenceInMinutes > 120) {
                    handlePrompt();
                }
                else {
                    getCourseList(parsedData?.user);
                }
            }

        }
        else {
            handlePrompt();
        }

    }

    const handlePrompt = async () => {

        const userResponse = prompt('Please enter your password:');
        setPassword(userResponse);
        getCourseList(userResponse)

    }
    return (
        <>
            <Routes>
                <Route path="/" element={
                    <>
                        {isValid && <>
                            {searchValue && (
                                <SearchBarComponent
                                    handleSearchSubmit={handleSearchSubmit}
                                    query={query}
                                    loading={loading}
                                    handleSearchChange={handleSearchChange}
                                    courselist={courseList}
                                />
                            )}

                            {courseStatus && (
                                <CourseOutline courseOutline={courseData} courseStage={courseStage} />
                            )}
                        </>}

                    </>} />



                {isValid && <Route path="/coursedata/:type/:id" element={<CourseData />} />}
                <Route path="/course/:id" element={<Course />} />
                <Route path="/login-expired" element={<LoginExpired />} />
            </Routes>
            {loading && (
                <div className="container-loader">
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                </div>
            )}
            <ToastContainer />
        </>
    );
}

export default App;
