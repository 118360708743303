import React, { useState } from "react";
import image from "./image.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBarsStaggered,
  faCircleCheck,
  faCircle,
} from "@fortawesome/free-solid-svg-icons";
import { NavLink, useNavigate } from "react-router-dom";
import Counts from "../data/Counts.json";

const Overview = ({
  isHomePage,
  activeCol = -1,
  completedStatus = {},
  setOverviewPage,
  id,
  courseData,
  courseTitle,
  hideOverview,
  setHideOverview,
}) => {
  console.log(id, "----", courseData);

  const [topics, setTopics] = useState(
    (courseData && courseData?.map((module) => module.moduleTitle)) || []
  );
  console.log(topics);
  const [homePage, setHomePage] = useState(isHomePage ?? true);
  const navigate = useNavigate();

  const isSmallScreen = window.innerWidth <= 475;
  console.log(isSmallScreen);

  const scroll = {
    overflow: "scroll",
    maxHeight: "100vh",
    overflowX: "hidden",
    overflowY: "auto",
    backgroundColor: "#fafafa",
  };

  const currentModuleNo = 0;
  const currentLessonNo = 0;
  console.log(currentModuleNo, currentLessonNo);
  const handleRowClick = (index) => {
    console.log("Row clicked for index:", index);

    // Safeguard if index is out of bounds
    if (!courseData[index]) {
      console.error("Invalid module index:", index);
      return;
    }

    const lessonNo = courseData[index].currentLessonNo;
    console.log("Navigating to lesson number:", lessonNo);

    // Navigate to the course
    navigate(`/course/${id}`, {
      replace: true, // This replaces the current history entry
      state: {
        module_no: index,
        currentLessonNumber: lessonNo,
        origin: "sidebar",
      },
    });

    setOverviewPage(false);

    if (isSmallScreen) setHideOverview(true);
  };

  return (
    <>
      <div
        className={`container-fluid p-0 m-0`}
        style={
          !homePage
            ? scroll
            : { backgroundColor: "#fafafa", overflow: "hidden", width: "100vw" }
        }
      >
        <div
          className={`row p-0 d-flex align-items-center justify-content-center ${
            homePage ? "overview-banner" : "overview-side-banner"
          }`}
          style={{
            backgroundImage: `url(${image})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        >
          <div
            className={
              homePage
                ? "col-6 col-md-6 col-sm-8 p-0 overview-title-main"
                : "col-9 col-md-9 col-sm-9 p-0 overview-title"
            }
          >
            <div
              role="link"
              style={{
                fontWeight: "bold",
                width: "100%",
                color: "#fff",
                cursor: `${homePage ? "" : "pointer"}`,
              }}
              onClick={
                !homePage ? () => setOverviewPage((prev) => !prev) : () => {}
              }
              className="d-flex justiy-content-center align-items-center"
            >
              {courseTitle}
            </div>
            {homePage ? (
              <NavLink
                to={`/course/${id}`}
                replace={true}
                state={{
                  module_no: currentModuleNo,
                  currentLessonNumber: currentLessonNo,
                  origin: "home",
                  overview: false,
                }}
              >
                <button
                  type="button"
                  className="btn mt-3 px-4 py-3 rounded-pill text-uppercase bg-white start-course-cls"
                  style={{
                    letterSpacing: "none",
                    backgroundColor: "#fff",
                    color: "#000",
                    border: "none",
                    fontSize: "12px",
                    fontFamily: `"Lato", sans-serif`,
                    fontWeight: "900",
                  }}
                  onClick={() => {
                    setHomePage(false);
                    setOverviewPage(false);
                  }}
                >
                  Start Course
                </button>
              </NavLink>
            ) : (
              <div
                className="progress mt-4"
                style={{ height: "3px", display: "none" }}
              >
                <div
                  className="progress-bar"
                  role="progressbar"
                  aria-valuenow="0"
                  aria-valuemin="0"
                  aria-valuemax="100"
                  style={{ backgroundColor: "hsla(0, 0%, 100%, .5)" }}
                ></div>
              </div>
            )}
          </div>
        </div>

        {topics && !homePage && (
          <div
            className={
              homePage
                ? "row d-flex justify-content-center p-0 m-0"
                : "row p-0 m-0"
            }
          >
            <div
              className={
                homePage
                  ? "col-6 col-md-6 col-sm-12 p-0"
                  : "col-12 col-md-12 col-sm-12 p-0"
              }
            >
              {/* {homePage && (
              <div className="table-heading p-0">
                <p className="fs-5 text-dark fst-italic">{courseTitle}</p>
                <p className="text-primary mt-5 fw-bolder fs-6">
                  Duration: <span className="text-dark">10 minutes</span>
                </p>
              </div>
            )} */}

              <div
                className={
                  homePage
                    ? `${"table-content m-0 mt-5"}`
                    : `${"table-content m-0"}`
                }
                style={{ padding: "28px 0" }}
              >
                <table className="table table-borderless table-hover">
                  <tbody>
                    {topics &&
                      topics.map((topic, index) => (
                        <tr
                          key={index}
                          className={index === activeCol ? "table-active" : ""}
                          style={
                            index === activeCol
                              ? {
                                  border: "none",
                                  borderLeft: "3px solid #bed804",
                                  backgroundColor: "#fafafa",
                                }
                              : {}
                          }
                          onClick={() => handleRowClick(index)}
                          role="button"
                        >
                          <th scope="row">
                            <FontAwesomeIcon
                              icon={faBarsStaggered}
                              style={{ color: "#717376" }}
                            />
                          </th>
                          <td
                            className="fs-6 fw-normal"
                            style={{
                              color: "#000",
                              fontSize: "13px",
                              fontFamily: `"lato", sans serif`,
                              fontWeight: "700",
                            }}
                          >
                            {topic}
                          </td>
                          <td>
                            {completedStatus[`${topic}`] ? (
                              <FontAwesomeIcon
                                icon={faCircleCheck}
                                style={{
                                  color: "#bed804",
                                  width: "20px",
                                  height: "20px",
                                }}
                              />
                            ) : (
                              <div
                                style={{
                                  display: "inline-flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  width: "20px", // Adjust size as needed
                                  height: "20px", // Adjust size as needed
                                  border: "3px solid gray",
                                  borderRadius: "50%",
                                }}
                              >
                                <FontAwesomeIcon
                                  icon={faCircle}
                                  style={{
                                    color: "transparent",
                                  }}
                                />
                              </div>
                            )}
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default Overview;
